import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import {
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    Select,
    FormControl,
    MenuItem,
    IconButton,
    colors,
    Popper,
    Fade,
    Tooltip
} from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import WarningIcon from "@material-ui/icons/Warning";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import Notify from "../../../../../components/Notify";
import EventLayout from "../../../../../components/EventLayout";
import ParticipantStatus from "../../../../../components/ParticipantStatus";
import { meeting as useStyles } from "../../../../../components/CommonAdminStyles";

import * as eventStore from "../../../../../store/ducks/event.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetingVideo from "./MeetingVideo";
import { jitsiMeeting, ORIGINAL_ROOMNAME } from "../../../../../components/JitsiMeeting";
import LeftPanel from "./LeftPanel";
import AudioDetector from "./components/AudioDetector";
import { AudioStatus } from "../../../../../components/utils/TrackUtils";
import { set } from "lodash";
import WarningMessage from "../../../../../components/WarningMessage";

function Meeting(props) {
    const {
        isEvent,
        event,
        isStartMeeting,
        startMeeting,
        endMeeting,
        screenMode,
        participants,
        audioTracks,
        audioLevels,
        intl
    } = props;
    const classes = useStyles(props);
    const [lang, setLang] = React.useState(ORIGINAL_ROOMNAME);
    const [volume, setVolume] = React.useState(0.5);
    const [isOriginal, setIsOriginal] = React.useState(false);
    const [roomsList, setRoomsList] = React.useState([]);
    const restart = localStorage.getItem("restart");
    const [translators, setTranslators] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedWarningUser, setSelectedWarningUser] = React.useState(null);

    useEffect(() => {
        if (isEvent && event) {
            const event_rooms = event.event_rooms;
            var resArr = [];
            event_rooms.filter(function(item) {
                var i = resArr.findIndex(x => x.room.room_name === item.room.room_name);
                if (i <= -1) {
                    resArr.push(item);
                }
                return null;
            });
            setRoomsList(resArr);

            if (restart === "1") {
                localStorage.removeItem("restart");
                handleStartMeeting();
            }
        }
    }, [isEvent, lang, event]);

    useEffect(() => {
        if (event && event.event_rooms) {
            const event_rooms = event.event_rooms;
            const _trans = [];
            for (let i = 0; i < event_rooms.length; i++) {
                if (event_rooms[i].interpreter) {
                    const interpreter = {
                        id: event_rooms[i].interpreter.id,
                        name: event_rooms[i].interpreter.name,
                        email: event_rooms[i].interpreter.email,
                        room: event_rooms[i].room.room_name,
                        input_lang: event_rooms[i].room.input_lang,
                        output_lang: event_rooms[i].room.output_lang
                    };

                    const p = participants.find(p => {
                        return p && p.getStatsID() === interpreter.email;
                    });
                    console.log("🚀 ~ p ~ p:", p);

                    interpreter["pID"] = p ? p.getId() : null;
                    interpreter["curr_output"] = p ? p.getProperty("output") : null;
                    interpreter["d_output"] = p ? p.getProperty("d_output") : null;

                    _trans.push(interpreter);
                }
            }

            setTranslators(_trans);
        }
    }, [participants, event]);

    function handleStartMeeting() {
        if (lang) {
            startMeeting(event.id);
        }
    }

    function handleEndMeeting() {
        endMeeting();
    }

    function handleLangChange(value) {
        setLang(value);
    }

    function handleDown(event) {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleOriginal() {
        setIsOriginal(prevState => !prevState);
    }

    function handleUp(event) {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    function _getLanguageChannelUserNumber(participants, room_name) {
        if (participants && participants.length > 0) {
            return participants.filter(
                p =>
                    p.getProperty("role") >= UserRole.USER &&
                    p.getProperty("roomname") === room_name
            ).length;
        } else {
            return 0;
        }
    }

    function handleReset() {
        localStorage.setItem("restart", 1);
        window.location.reload();
    }

    function getPariticpantByEmail(pEmail) {
        const p = participants.find(p => {
            return p && p.getStatsID() === pEmail;
        });
        return p ? p.getId() : null;
    }

    function getParticipantInOutStatus(pEmail, roomname) {
        const p = participants.find(p => {
            return p && p.getStatsID() === pEmail;
        });

        if (p) {
            const p_output = p.getProperty("output");

            const is_non = p_output ? p_output !== roomname || p_output.includes("non-") : false;

            let _isMuted = true;
            try {
                const p_audioTracks = p.getTracksByMediaType("audio");
                if (p_audioTracks && p_audioTracks.length > 0) {
                    _isMuted = p_audioTracks[0].muted;
                }
            } catch (e) {
                console.error("🚀 ~ getParticipantInOutStatus ~ p", p);
                console.error("🚀 ~ getParticipantInOutStatus ~ e", e);
            }

            if (is_non) {
                return AudioStatus.OFF;
            } else {
                return _isMuted ? AudioStatus.MUTED : AudioStatus.ON;
            }
        }

        return AudioStatus.OFF;
    }

    function muteParticipant(pl) {
        const p = participants.find(e => {
            return pl && e.getStatsID() === pl.email;
        });
        if (p) {
            const p_audioTracks = p.getTracksByMediaType("audio");
            if (p_audioTracks && p_audioTracks.length > 0 && p_audioTracks[0].muted) {
                jitsiMeeting.sendPreMsg(pl.pID, {
                    msg: "Please turn on your mic",
                    msg_en: "Please turn on your mic"
                });
            }
            const id = p ? p.getId() : null;
            if (id) {
                try {
                    jitsiMeeting.room.muteParticipant(id);
                } catch (e) {
                    console.error("🚀 ~ muteParticipant ~ e", e);
                }
            }
        }
    }

    function switchChannel(id) {
        if (id) {
            try {
                jitsiMeeting.switchChannel(id);
            } catch (e) {
                console.error("🚀 ~ switchChannel ~ e", e);
            }
        }
    }

    function renderParticipantLabel(e) {
        const pLabel = `INT ${e.input_lang ? _.upperCase(e.input_lang.lang_name) : ""}/${
            e.output_lang ? _.upperCase(e.output_lang.lang_name) : ""
        } (${_.upperFirst(e.name)})`;
        return (
            <Tooltip title={pLabel}>
                <span className={classes.label}>{pLabel}</span>
            </Tooltip>
        );
    }

    function renderChannels(room, event_rooms) {
        return (
            <table key={`table${room.id}`}>
                <tbody>
                    {translators
                        .filter(t => {
                            if (t.curr_output === null) {
                                if (t.room === room.room.room_name) {
                                    return true;
                                }
                            } else {
                                if (
                                    t.curr_output === room.room.room_name ||
                                    t.curr_output === `non-${room.room.room_name}`
                                ) {
                                    return true;
                                }
                            }
                            return false;
                        })
                        .map(e => (
                            <tr
                                key={e.id}
                                className={`
                                        ${
                                            getMatchedStatus(participants, e.email)
                                                ? classes.onlineStatus
                                                : classes.offlineStatus
                                        } ${classes.statusLabel}
                                    `}
                            >
                                <td className={classes.panelStatusTableTD}>
                                    <div className={classes.statusWrapper}>
                                        <ParticipantStatus
                                            status={getParticipantInOutStatus(
                                                e.email,
                                                room.room.room_name
                                            )}
                                        />{" "}
                                        {renderParticipantLabel(e)}
                                    </div>
                                </td>
                                <td className={classes.panelStatusTableTD}>
                                    <AudioDetector
                                        direction="horizontal"
                                        pId={getPariticpantByEmail(e.email)}
                                        audioLevels={audioLevels}
                                    />
                                </td>
                                <td className={classes.panelStatusTableTD}>
                                    <IconButton size="small" onClick={() => switchChannel(e.pID)}>
                                        <FlipCameraAndroidIcon />
                                    </IconButton>
                                </td>
                                <td className={classes.panelStatusTableTD}>
                                    <IconButton size="small" onClick={() => muteParticipant(e)}>
                                        <MicOffIcon />
                                    </IconButton>
                                </td>
                                <td className={classes.panelStatusTableTD}>
                                    <IconButton
                                        size="small"
                                        onClick={event =>
                                            handleWarningMessagePopup(event, {
                                                pID: e.pID,
                                                label: `INT ${
                                                    e.input_lang ? e.input_lang.lang_name : ""
                                                }/${
                                                    e.output_lang ? e.output_lang.lang_name : ""
                                                } (${e.name})`
                                            })
                                        }
                                    >
                                        <WarningIcon style={{ color: colors.yellow[500] }} />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    }

    function getMatchedStatus() {
        return true;
    }

    function handleWarningMessagePopup(event, user) {
        setAnchorEl(event.currentTarget);
        setSelectedWarningUser(user);
        setOpen(prev => !prev);
    }

    function sendWarningMessage(pID, value) {
        if (value && value.length > 0 && pID) {
            jitsiMeeting.sendPreMsg(pID, { msg: value, msg_en: value });
        }
    }

    return (
        <EventLayout
            event={event}
            screenMode={screenMode}
            isStartMeeting={isStartMeeting}
            hidden={isStartMeeting}
        >
            <div className={`row ${classes.eventPlace}`} id="eventPlace">
                {isStartMeeting && (
                    <div className={`col-md-2 ${classes.leftPanelContainer}`} id="leftPanel">
                        <LeftPanel event={event} roomsList={roomsList} />{" "}
                    </div>
                )}
                <div className={`col-md-7 ${classes.videoPlace}`} id="videoPlace">
                    <Notify />
                    {event && isStartMeeting ? (
                        <MeetingVideo
                            event={event}
                            roomname={lang}
                            volume={volume}
                            size="sm"
                            isOriginal={isOriginal}
                            roomsList={roomsList}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className={`col-md-3 ${classes.control} ${screenMode < 0 &&
                        classes.fullWidthControl}`}
                >
                    <div className={classes.rightPanel}>
                        <div>
                            {isStartMeeting && (
                                <>
                                    <Grid
                                        container
                                        className="col-md-12 mb-3"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={`${
                                                lang == ORIGINAL_ROOMNAME
                                                    ? classes.langActiveBtn
                                                    : ""
                                            } m-2`}
                                            onClick={() => handleLangChange(ORIGINAL_ROOMNAME)}
                                        >
                                            FLOOR
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={`${
                                                isOriginal ? classes.langActiveBtn : ""
                                            } m-2`}
                                            onClick={() => handleOriginal()}
                                        >
                                            WITH FLOOR
                                        </Button>
                                    </Grid>
                                    <div className={`col-md-12 ${classes.paddingLeft0}`}>
                                        <Paper className={classes.langControl}>
                                            {event &&
                                                roomsList.map(room => (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        key={room.id}
                                                        m={1}
                                                        className={classes.controlRow}
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                size="small"
                                                                className={`${
                                                                    lang == room.room.room_name
                                                                        ? classes.langActiveBtn
                                                                        : ""
                                                                } m-2`}
                                                                color={
                                                                    lang === room.room.room_name
                                                                        ? "primary"
                                                                        : "secondary"
                                                                }
                                                                onClick={() =>
                                                                    handleLangChange(
                                                                        room.room.room_name
                                                                    )
                                                                }
                                                                variant="outlined"
                                                            >
                                                                {room.room.output_lang.lang_name}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Popper
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                placement={"top"}
                                                                transition
                                                            >
                                                                {({ TransitionProps }) => (
                                                                    <Fade
                                                                        {...TransitionProps}
                                                                        timeout={350}
                                                                    >
                                                                        <WarningMessage
                                                                            selectedUser={
                                                                                selectedWarningUser
                                                                                    ? selectedWarningUser.label
                                                                                    : ""
                                                                            }
                                                                            sendWarningMessage={msg =>
                                                                                sendWarningMessage(
                                                                                    selectedWarningUser
                                                                                        ? selectedWarningUser.pID
                                                                                        : "",
                                                                                    msg
                                                                                )
                                                                            }
                                                                        />
                                                                    </Fade>
                                                                )}
                                                            </Popper>
                                                            {renderChannels(
                                                                room,
                                                                event.event_rooms
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </Paper>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Paper className={classes.channelUsersContainer}>
                                    <span>
                                        {intl.formatMessage({
                                            id: "EVENT.LANG.ORIGINAL"
                                        })}{" "}
                                        - {_getLanguageChannelUserNumber(participants, "original")}
                                    </span>
                                    {roomsList.map((row, index) => (
                                        <span key={index}>
                                            ,{" "}
                                            {row.room.output_lang
                                                ? row.room.output_lang.lang_label
                                                : ""}{" "}
                                            -{" "}
                                            {_getLanguageChannelUserNumber(
                                                participants,
                                                row.room.room_name
                                            )}
                                        </span>
                                    ))}
                                </Paper>
                            </div>

                            <div className="col-md-12">
                                <Portlet>
                                    <PortletBody>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="center" spacing={2}>
                                                    <Grid item xs={6}>
                                                        {isStartMeeting ? (
                                                            <Button
                                                                className={classes.paper}
                                                                disabled={!isStartMeeting}
                                                                onClick={handleEndMeeting}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: "EVENT.ACTION.END_MEETING"
                                                                })}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className={classes.paper}
                                                                disabled={isStartMeeting}
                                                                onClick={handleStartMeeting}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: "EVENT.ACTION.START_MEETING"
                                                                })}
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            className={classes.paper}
                                                            disabled={!isStartMeeting}
                                                            onClick={handleReset}
                                                        >
                                                            {intl.formatMessage({
                                                                id: "EVENT.ACTION.RESET"
                                                            })}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </PortletBody>
                                </Portlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </EventLayout>
    );
}

const mapStateToProps = state => {
    return {
        event: state.events.event,
        isEvent: state.events.isEvent,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode,
        participants: state.event.participants,
        audioTracks: state.event.audioTracks,
        audioLevels: state.event.audioLevels
    };
};

const mapDispatchToProps = dispatch => ({
    startMeeting: event_id => dispatch(eventStore.actions.startMeeting(event_id)),
    endMeeting: () => dispatch(eventStore.actions.endMeeting())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Meeting));
