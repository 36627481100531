import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
    Paper,
    Box,
    List,
    ListItem,
    ListItemText,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    Select,
    FormControl,
    MenuItem,
    IconButton,
    Popper,
    Fade,
    Tooltip
} from "@material-ui/core";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import SendIcon from "@material-ui/icons/Send";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles, colors } from "@material-ui/core";
import * as eventsStore from "../../../../../store/ducks/events.duck";
import ParticipantStatus from "../../../../../components/ParticipantStatus";
import AudioIndicator from "../../../../../components/AudioIndicator";
import AudioDetector from "./components/AudioDetector";
import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import { AudioStatus } from "../../../../../components/utils/TrackUtils";
import { UserRole } from "../../../../../components/utils/UserRole";
import WarningMessage from "../../../../../components/WarningMessage";
const { common } = colors;

export const LEFTPANEL_WIDTH = 300;
export const panelStyle = makeStyles(theme => ({
    leftPanelContainer: {
        maxWidth: LEFTPANEL_WIDTH,
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 56px)",
        fontWeight: theme.typography.fontWeightMedium
    },
    leftPanelList: {
        height: "100%",
        padding: theme.spacing(4),
        paddingLeft: 2,
        paddingRight: 2
    },
    listWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    leftPanelStatus: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2)
    },
    label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    selected: {
        border: "solid 1px green"
    },
    statusLabel: {
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: 8,
        paddingRight: 8
    },
    onlineStatus: {
        color: "black"
    },
    offlineStatus: {
        color: "#7a032e"
    },
    leftPanelStatusTable: {
        width: "100%"
    },
    leftPanelStatusTableTD: {
        border: "solid 1px black",
        textAlign: "center"
    },
    statusWrapper: {
        display: "flex",
        marginBottom: theme.spacing(2)
    }
}));

function LeftPanel(props) {
    const {
        event,
        roomsList,
        participantsList,
        participants,
        getParticipantsList,
        audioTracks,
        audioLevels
    } = props;

    const classes = panelStyle();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedParticipant, setSelectedParticipant] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (event.id) {
            getParticipantsList(event.id);
        }
    }, [event, getParticipantsList]);

    const handleListItemClick = (ele, statuslabel) => {
        setSelectedIndex(ele.email);
        setSelectedUser(statuslabel);
        const p = participants.find(p => {
            return p && p.getStatsID() === ele.email;
        });

        setSelectedParticipant(p);
    };

    const getMatchedStatus = (pList, email) => {
        const p = pList.filter(p => {
            return p && p.getStatsID() === email;
        });
        return p.length > 0;
    };

    const getParticipantInOutStatus = p => {
        if (p) {
            const p_output = p.getProperty("output");

            const is_non = p_output ? p_output.includes("non-") : false;

            let _isMuted = true;
            const p_audioTracks = p.getTracksByMediaType("audio");

            if (p_audioTracks && p_audioTracks.length > 0) {
                _isMuted = p_audioTracks[0].muted;
            }
            if (is_non) {
                return AudioStatus.OFF;
            } else {
                return _isMuted ? AudioStatus.MUTED : AudioStatus.ON;
            }
        }

        return AudioStatus.OFF;
    };

    function muteParticipant(p) {
        if (p) {
            const p_audioTracks = p.getTracksByMediaType("audio");
            if (p_audioTracks && p_audioTracks.length > 0 && p_audioTracks[0].muted) {
                jitsiMeeting.sendPreMsg(selectedParticipant.getId(), {
                    msg: "Please turn on your mic",
                    msg_en: "Please turn on your mic"
                });
            }
            const id = p ? p.getId() : null;
            if (id) {
                try {
                    jitsiMeeting.room.muteParticipant(id);
                } catch (e) {
                    console.error("🚀 ~ muteParticipant ~ e", e);
                }
            }
        }
    }

    function switchChannel(id) {
        if (id) {
            try {
                jitsiMeeting.switchChannel(id);
            } catch (e) {
                console.error("🚀 ~ switchChannel ~ e", e);
            }
        }
    }

    function handleWarningMessagePopup(event) {
        setAnchorEl(event.currentTarget);

        setOpen(prev => !prev);
    }

    function sendWarningMessage(value) {
        if (value && value.length > 0 && selectedParticipant) {
            jitsiMeeting.sendPreMsg(selectedParticipant.getId(), { msg: value, msg_en: value });
        }
    }

    function renderParticipantLabel(e) {
        const pLabel = `INT ${e.room.input_lang ? _.upperCase(e.room.input_lang.lang_name) : ""}/${
            e.room.output_lang ? _.upperCase(e.room.output_lang.lang_name) : ""
        } (${_.upperFirst(e.interpreter.name)})`;
        return (
            <Tooltip title={pLabel}>
                <span className={classes.label}>{pLabel}</span>
            </Tooltip>
        );
    }

    return (
        <div className={classes.leftPanelContainer}>
            <Paper className={classes.leftPanelList}>
                <List
                    classes={{ root: classes.listWrapper }}
                    component="nav"
                    aria-label="main mailbox folders"
                    dense
                >
                    {event.emitter && (
                        <ListItem
                            key={event.emitter.id}
                            button
                            selected={selectedIndex === event.emitter.email}
                            onClick={() => handleListItemClick(event.emitter, event.emitter.name)}
                            className={`
                                ${selectedIndex === event.emitter.email ? classes.selected : ""} ${
                                getMatchedStatus(participants, event.emitter.email)
                                    ? classes.onlineStatus
                                    : classes.offlineStatus
                            } ${classes.statusLabel}
                            `}
                        >
                            {event.emitter.name}
                        </ListItem>
                    )}
                    {roomsList.map(room =>
                        event.event_rooms
                            .filter(
                                ele =>
                                    ele.interpreter_id !== 0 &&
                                    ele.room.room_name === room.room.room_name
                            )
                            .map(e =>
                                e.interpreter ? (
                                    <ListItem
                                        key={e.id}
                                        button
                                        selected={selectedIndex === e.interpreter.email}
                                        onClick={() =>
                                            handleListItemClick(
                                                e.interpreter,
                                                `INT
                                            ${e.room.input_lang ? e.room.input_lang.lang_name : ""}/
                                            ${
                                                e.room.output_lang
                                                    ? e.room.output_lang.lang_name
                                                    : ""
                                            } (${e.interpreter.name})`
                                            )
                                        }
                                        className={`
                                            ${
                                                selectedIndex === e.interpreter.email
                                                    ? classes.selected
                                                    : ""
                                            } ${
                                            getMatchedStatus(participants, e.interpreter.email)
                                                ? classes.onlineStatus
                                                : classes.offlineStatus
                                        } ${classes.statusLabel}
                                        `}
                                    >
                                        {renderParticipantLabel(e)}
                                    </ListItem>
                                ) : (
                                    ""
                                )
                            )
                    )}
                    {participantsList.map(p =>
                        p.email && p.name ? (
                            <ListItem
                                key={p.id}
                                button
                                onClick={() => handleListItemClick(p, p.name)}
                                selected={selectedIndex === p.email}
                                className={`${selectedIndex === p.email ? classes.selected : ""}  ${
                                    getMatchedStatus(participants, p.email)
                                        ? classes.onlineStatus
                                        : classes.offlineStatus
                                } ${classes.statusLabel} ${p.email} ${p.id}
                            `}
                            >
                                {p.name}
                            </ListItem>
                        ) : (
                            <div key={p.id} />
                        )
                    )}
                </List>
            </Paper>
            <Paper className={classes.leftPanelStatus}>
                <div className={classes.statusWrapper}>
                    <ParticipantStatus status={getParticipantInOutStatus(selectedParticipant)} />{" "}
                    <Box component="span" ml={1}>
                        {selectedUser}
                    </Box>
                </div>
                <div>
                    <table className={classes.leftPanelStatusTable}>
                        <tbody>
                            <tr>
                                <td className={classes.leftPanelStatusTableTD}>
                                    <AudioDetector
                                        direction="horizontal"
                                        pId={
                                            selectedParticipant ? selectedParticipant.getId() : null
                                        }
                                        audioLevels={audioLevels}
                                    />
                                </td>
                                <td className={classes.leftPanelStatusTableTD}>
                                    <IconButton
                                        size="small"
                                        disabled={
                                            !selectedParticipant ||
                                            parseInt(selectedParticipant.getProperty("role")) !==
                                                UserRole.INTERPRETER
                                        }
                                        onClick={() =>
                                            switchChannel(
                                                selectedParticipant
                                                    ? selectedParticipant.getId()
                                                    : null
                                            )
                                        }
                                    >
                                        <FlipCameraAndroidIcon />
                                    </IconButton>
                                </td>
                                <td className={classes.leftPanelStatusTableTD}>
                                    <IconButton
                                        size="small"
                                        onClick={() => muteParticipant(selectedParticipant)}
                                    >
                                        <MicOffIcon />
                                    </IconButton>
                                </td>
                                <td className={classes.leftPanelStatusTableTD}>
                                    <Popper
                                        open={open}
                                        anchorEl={anchorEl}
                                        placement={"top"}
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <WarningMessage
                                                    selectedUser={selectedUser}
                                                    sendWarningMessage={sendWarningMessage}
                                                />
                                            </Fade>
                                        )}
                                    </Popper>
                                    <IconButton size="small" onClick={handleWarningMessagePopup}>
                                        <WarningIcon style={{ color: colors.yellow[500] }} />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Paper>
            <div></div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        participants: state.event.participants,
        participantsList: state.events.participantsList,
        audioTracks: state.event.audioTracks,
        audioLevels: state.event.audioLevels
    };
};

const mapDispatchToProps = dispatch => ({
    getParticipantsList: eventId => dispatch(eventsStore.actions.getParticipantsList(eventId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LeftPanel));
